import React, { ReactNode, useContext, useId, useMemo, useState } from 'react';

import { Spinner } from '@/components/ui/spinner';

export type LoadingContextState = {
  loader: {
    on: (key: string) => unknown;
    off: (key: string) => unknown;
  };
};

const LoadingContext = React.createContext<LoadingContextState | undefined>(
  undefined,
);

function LoadingProvider(props: { children?: ReactNode }) {
  const [loadingState, setLoadingState] = useState<{ [key: string]: true }>({});
  const { children } = props;

  const value = useMemo<LoadingContextState>(
    () => ({
      loader: {
        on: (key: string) =>
          setLoadingState((set) => ({
            ...set,
            [key]: true,
          })),
        off: (key: string) =>
          setLoadingState((set) => {
            const newSet = { ...set };

            delete newSet[key];

            return newSet;
          }),
      },
    }),
    [],
  );

  const isLoading = Object.keys(loadingState).length > 0;

  return (
    <LoadingContext.Provider value={value}>
      {isLoading && (
        <Spinner.SpinnerOverlay zIndex={100}>
          <Spinner />
        </Spinner.SpinnerOverlay>
      )}

      {children}
    </LoadingContext.Provider>
  );
}

export function useLoading() {
  const context = useContext(LoadingContext);
  const id = useId();

  if (context === undefined) {
    throw new Error(`useLoading should be used within LoadingProvider`);
  }

  return {
    loader: {
      on: () => context.loader.on(id),
      off: () => context.loader.off(id),
    },
  };
}

export default LoadingProvider;
