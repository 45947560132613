import { env } from '@/env.mjs';
import InvalidUserCredential from '@/errors/InvalidUserCredentials';
import { useLoginMutation } from '@/generated/graphql';

function useLogin() {
  const [loginMutation] = useLoginMutation();

  return async (username: string, password: string) => {
    const response = await loginMutation({
      variables: {
        username,
        password,
      },
    });

    if (!response.data?.login) {
      throw new InvalidUserCredential();
    }
    if (!response.data.login.isArtist && !response.data.login.isDoorPerson) {
      window.location.href = env.NEXT_PUBLIC_BACKEND_URL;
    }

    return response;
  };
}

export default useLogin;
