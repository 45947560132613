'use client';

import { captureException } from '@sentry/core';
import * as Sentry from '@sentry/nextjs';
import React, {
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import toast from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';

import { setFrontChatUser } from '@/components/ReactClientComponents/FrontChat';
import { env } from '@/env.mjs';
import { useLogoutMutation, UserFragment } from '@/generated/graphql';
import useFetching from '@/hooks/useFetching';
import { useInternalRouter } from '@/hooks/useInternalRouter';
import useLogin from '@/hooks/useLoginMutation';
import useUserQuery from '@/hooks/useUserQuery';
import { useLoading } from '@/utils/Loading/LoadingProvider';
import { Mixpanel } from '@/utils/mixpanel';

type AuthContextType = {
  me: UserFragment | null;
  isLoading: boolean;
  refetch: { (): Promise<unknown> };
  login: Dispatch<{ username: string; password: string }>;
  logout: { (_: unknown): unknown };
};

const AuthContext = React.createContext<AuthContextType>({
  me: null,
  isLoading: true,
  refetch: () => Promise.resolve(),
  login: () => null,
  logout: () => null,
});

function AuthProvider(props: { children?: React.ReactNode }) {
  const { children } = props;
  const { data: user, error, isLoading, mutate } = useUserQuery();

  const router = useInternalRouter();

  const { update } = useIntercom();

  useFetching({
    data: user,
    error,
    isLoading,
  });

  useEffect(() => {
    if (user) {
      if (env.NEXT_PUBLIC_INTERCOM_APP_ID) {
        update({
          userId: user.uuid,
          email: user.email,
          userHash: user.intercomHmac,
          createdAt: user.dateJoined,
          phone: user.phonenumber,
          name: user.username,
          avatar:
            user.artists.length > 0
              ? {
                  type: `avatar`,
                  imageUrl: user.artists[0].photo,
                }
              : undefined,
        });
      }
      Sentry.setUser({ username: user.username });
      Mixpanel.identify(String(user.id));
      Mixpanel.people.set({
        $name: user.username,
        $email: user.email,
        ...user,
      });
      setFrontChatUser({
        email: user.email,
        name: user.username,
        userHash: user.frontHmac,
      });
    } else {
      Sentry.setUser(null);
    }
  }, [update, user]);

  const refetch = useCallback(async () => mutate(), [mutate]);

  const { loader } = useLoading();

  const login = useLogin();
  const handleLogin = useCallback(
    async ({ username, password }: { username: string; password: string }) => {
      loader.on();
      try {
        await login(username, password);
        await refetch();
        toast.success(`Signed in successfully`);
        loader.off();
      } catch (e) {
        toast.error(`${e}`);
        captureException(e);
      }
      loader.off();
    },
    [loader, login, refetch],
  );

  const [logoutMutation] = useLogoutMutation();
  const logout = useCallback(async () => {
    loader.on();
    try {
      await logoutMutation();
      window?.scrollTo(0, 0);
      router.replace(`/signin`);
      await refetch();
      loader.off();
    } catch (e) {
      toast.error(`${e}`);
      captureException(e);
    }
    loader.off();
  }, [loader, logoutMutation, refetch, router]);

  const value = useMemo<AuthContextType>(
    () => ({
      me: user ?? null,
      isLoading,
      refetch,
      login: handleLogin,
      logout,
    }),
    [user, isLoading, refetch, handleLogin, logout],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return useContext(AuthContext);
}

export function useAuthed() {
  const context = useContext(AuthContext);

  if (!context.me) {
    throw new Error(`User is not authenticated`);
  }

  return {
    me: context.me,
  };
}

export { AuthContext, AuthProvider, useAuth };
