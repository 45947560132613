import toast from 'react-hot-toast';
import useSWR from 'swr';

import {
  UserDocument,
  UserQuery,
  UserQueryVariables,
} from '@/generated/graphql';
import client from '@/graphql/apollo';

function useUserQuery() {
  return useSWR(`/auth`, async () => {
    try {
      const response = await client.query<UserQuery, UserQueryVariables>({
        query: UserDocument,
      });

      if (!response.data.me) {
        return null;
      }

      if (!response.data.me.isArtist && !response.data.me.isDoorPerson) {
        toast(`You are not an artist user. Login as an artist user.`);

        return null;
      }

      return response.data.me;
    } catch (e) {
      if (e instanceof Error && e.message === `User is not authenticated`) {
        return null;
      }

      throw e;
    }
  });
}

export default useUserQuery;
