class InvalidUserCredential extends Error {
  constructor(msg?: string) {
    super(msg ?? `Invalid User Credential`);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, InvalidUserCredential.prototype);
  }
}

export default InvalidUserCredential;
