import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
  text-align: center;
  -webkit-animation: sk-rotate 2s infinite linear;
  animation: sk-rotate 2s infinite linear;
`;
export const SpinnerDot1 = styled.div`
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  top: 0;
  background-color: #f9820b;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
`;
export const SpinnerDot2 = styled.div`
  width: 60%;
  height: 60%;
  display: inline-block;
  position: absolute;
  background-color: #f9820b;
  border-radius: 100%;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
`;

export function Spinner() {
  return (
    <SpinnerWrapper>
      <SpinnerDot1 />
      <SpinnerDot2 />
    </SpinnerWrapper>
  );
}

type SpinnerOverlayProps = {
  children?: ReactNode;
  zIndex?: number;
};
function SpinnerOverlay(props: SpinnerOverlayProps) {
  const { zIndex, children } = props;

  return (
    <div
      className="fixed inset-x-0 top-[50%] mx-auto flex h-screen w-screen translate-y-[-50%] items-center justify-center bg-transparent"
      style={{
        zIndex,
      }}
    >
      <div className="fixed size-full bg-gray-400 opacity-50" />
      {children}
    </div>
  );
}

Spinner.SpinnerOverlay = SpinnerOverlay;
