'use client';

import mixpanel, { Dict, Query } from 'mixpanel-browser';

import { env } from '@/env.mjs';
import { useNavigationEvent } from '@/hooks/useNavigationEvent';

mixpanel.init(env.NEXT_PUBLIC_MIXPANEL_PROJECT_TOKEN, {
  // Use your project's URL, adding a slug for all Mixpanel requests
  api_host: `/mp`,
});

export const Mixpanel = {
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  track: (name: string, props?: Dict) => {
    mixpanel.track(name, props);
  },
  track_links: (query: Query, name: string) => {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    });
  },
  people: {
    set: (props: Dict) => {
      // mixpanel.people.set(props);
    },
  },
};

export function useRouteChangeComplete() {
  const handleRouteChange = (url: string) => {
    // Send track event when new pages is loaded
    Mixpanel.track(`Page view`, {
      url,
    });
  };

  useNavigationEvent(handleRouteChange);
}
