import { captureException } from '@sentry/core';
import { useEffect } from 'react';
import toast from 'react-hot-toast';

import { useLoading } from '@/utils/Loading/LoadingProvider';

function useFetching<T>(props: {
  data: T;
  isLoading: boolean;
  error: Error | undefined;
}) {
  const { data, isLoading, error } = props;
  const { loader } = useLoading();

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      captureException(error);
    }
  }, [error]);

  useEffect(() => {
    if (isLoading && !data) {
      loader.on();
    } else {
      loader.off();
    }

    return () => {
      loader.off();
    };
  }, [data, isLoading, loader]);
}

export default useFetching;
