import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client';
import { SentryLink } from 'apollo-link-sentry';
import { createUploadLink } from 'apollo-upload-client';

const client = new ApolloClient({
  link: ApolloLink.from([
    new SentryLink({
      attachBreadcrumbs: {
        includeQuery: true,
        includeVariables: true,
        includeFetchResult: true,
        includeError: true,
        includeCache: true,
      },
    }),
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    createUploadLink({
      uri: `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
      fetchOptions: {
        mode: `cors`,
      },
      credentials: `include`,
    }),
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: `no-cache`,
      errorPolicy: `none`,
    },
    query: {
      fetchPolicy: `no-cache`,
      errorPolicy: `none`,
    },
  },
});

export default client;
