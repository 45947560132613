'use client';

import Script from 'next/script';
import { useMediaQuery } from 'react-responsive';

import { env } from '@/env.mjs';

declare global {
  interface Window {
    FrontChat?: {
      (
        command: string,
        params?: Record<string, string | boolean | object>,
      ): void;
      // This isn't part of the FrontChat API; we add it to track when Front is fully initialized
      hasInitialized: boolean;
    };
  }
}

export function FrontChat() {
  const isMd = useMediaQuery({
    query: `(min-width: 768px)`,
  });

  if (!env.NEXT_PUBLIC_FRONT_CHAT_ID) {
    return null;
  }

  return (
    <Script
      id="front-chat-script"
      src="https://chat-assets.frontapp.com/v1/chat.bundle.js"
      onLoad={() => initFrontChat(isMd)}
    />
  );
}

function initFrontChat(useDefaultLauncher: boolean) {
  if (!env.NEXT_PUBLIC_FRONT_CHAT_ID || !window.FrontChat) {
    return;
  }

  window.FrontChat(`init`, {
    chatId: env.NEXT_PUBLIC_FRONT_CHAT_ID,
    useDefaultLauncher,
    onInitCompleted: () => {
      if (window.FrontChat) window.FrontChat.hasInitialized = true;
    },
  });
}

export function setFrontChatUser(user: {
  email: string;
  name: string;
  userHash: string;
}) {
  if (!env.NEXT_PUBLIC_FRONT_CHAT_ID) {
    return;
  }
  if (!window.FrontChat || !window.FrontChat.hasInitialized) {
    setTimeout(() => {
      setFrontChatUser(user);
    }, 1000);

    return;
  }
  window.FrontChat(`identity`, {
    email: user.email,
    name: user.name,
    userHash: user.userHash,
  });
}

export function showFrontMessengerPanel() {
  if (!env.NEXT_PUBLIC_FRONT_CHAT_ID) {
    return;
  }
  if (!window.FrontChat || !window.FrontChat.hasInitialized) {
    setTimeout(() => {
      showFrontMessengerPanel();
    }, 1000);

    return;
  }
  window.FrontChat(`show`);
}
